import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import { Layout } from "../../Layout";
import { supabase } from "../../supabaseClient";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { CheckCircle, Copy, Link2 } from "react-feather";
import { download_files } from "../../utils/downloadFiles";
import { Link } from "react-router-dom";
import { ArrowLeft, Info } from "react-feather";
import type { Post } from "../../types/api/Post";
import { Feed } from "../../types/api/Feed";
import { PostsForFeed } from "../../types/api/PostsForFeeds";
import { getSignedUrl } from "../../api/files";
import axios from "axios";
import { getAccessTokenHeader } from "../../api/helper/getAccessTokenHeader";
import { handleError } from "../../utils/handleError";
import { getBackend } from "../../utils/getBackend";
import { formatLocation } from "../../utils/formatLocation";
import { Location } from "../../types/api/Location";
import { PhotoViewer } from "../../components/PhotoViewer";
import { SelectedFile } from "../../types/PostSelection";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function Post() {
  const { session } = useContext(AuthContext);
  const { postId } = useParams();
  const [post, setPost] = useState<Post | null>(null);
  const [posting, setPosting] = useState({
    instagram: false,
    threads: false,
    facebook: false,
  });
  const [viewPhoto, setViewPhoto] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);

  useEffect(() => {
    getPosts();
  }, [session]);

  const closePhotoViewer = () => {
    setViewPhoto(null);
  };

  const getPosts = async () => {
    let { data: post, error } = await supabase
      .from("posts")
      .select(
        `
        *,
        posts_media(*),
        posts_for_feeds(
          *,
          feed(*)
        )
      `
      )
      .eq("_id", postId!)
      .limit(1)
      .single();

    if (post) {
      post.posts_for_feeds = post.posts_for_feeds.sort(
        (a, b) => a.feed_id! - b.feed_id!
      );

      for (const media of post.posts_media) {
        media.src_thumbnail = await getSignedUrl(media.src_thumbnail!);
        media.src_3600 = await getSignedUrl(media.src_3600!);
      }

      const selectedFiles =
        post?.posts_media
          ?.sort((a, b) => (a?.order && b?.order ? a.order - b.order : 0))
          .map((f) => ({
            _id: f.id + "",
            id: f.id!,
            name: f.title!,
            url: f.src_original!,
            mine_type: f.mine_type!,
          })) ?? [];

      for (const f of selectedFiles) {
        f.url = await getSignedUrl(f.url!);
      }

      setSelectedFiles(selectedFiles);

      setPost(post);
    }
  };

  const downloadFiles = async () => {
    if (post?.posts_media) {
      download_files(
        post.posts_media.map((p) => ({
          download: p.src_3600!,
          filename: p.title!,
        }))
      );
    }
  };

  const postToInstagram = async (p: PostsForFeed) => {
    setPosting((s) => ({ ...s, instagram: true }));
    try {
      await axios.post(
        getBackend() + `/instagram/publish`,
        {
          user_id: session?.user.id,
          post_id: post?._id,
        },
        {
          headers: await getAccessTokenHeader(),
        }
      );

      await publishPost(p, true);

      console.log("Successful post!");
      setPosting((s) => ({ ...s, instagram: false }));
    } catch (e) {
      handleError(e as Error);
      console.error("Error posting to Instagram", e);
      setPosting((s) => ({ ...s, instagram: false }));
    }
  };

  const postToFacebook = async (p: PostsForFeed) => {
    setPosting((s) => ({ ...s, facebook: true }));
    try {
      await axios.post(
        getBackend() + `/facebook/publish`,
        {
          user_id: session?.user.id,
          post_id: post?._id,
        },
        {
          headers: await getAccessTokenHeader(),
        }
      );

      await publishPost(p, true);

      console.log("Successful post!");
      setPosting((s) => ({ ...s, facebook: false }));
    } catch (e) {
      handleError(e as Error);
      console.error("Error posting to Facebook", e);
      setPosting((s) => ({ ...s, facebook: false }));
    }
  };

  const postToThreads = async (p: PostsForFeed) => {
    setPosting((s) => ({ ...s, threads: true }));
    try {
      await axios.post(
        getBackend() + "/threads/posts/publish",
        {
          user_id: session?.user.id,
          post_id: post?._id,
        },
        {
          headers: await getAccessTokenHeader(),
        }
      );

      await publishPost(p, true);

      console.log("Successful post!");
      setPosting((s) => ({ ...s, threads: false }));
    } catch (e) {
      handleError(e as Error);
      console.error("Error posting to Instagram", e);
      setPosting((s) => ({ ...s, threads: false }));
    }
  };

  const publishPost = async (p: PostsForFeed, disableCopy?: boolean) => {
    if (!disableCopy) {
      navigator.clipboard.writeText(postWithLocation(p));
    }
    const { data: postForFeed } = await supabase
      .from("posts_for_feeds")
      .update({
        published: !p.published,
      })
      .eq("id", p.id)
      .select(
        `*,
          feed(*)`
      )
      .single();

    if (post?.posts_for_feeds) {
      const index = post.posts_for_feeds.findIndex((pfd) => pfd.id === p.id);
      post.posts_for_feeds = [
        ...post.posts_for_feeds.slice(0, index),
        postForFeed!,
        ...post.posts_for_feeds.slice(index + 1),
      ];

      setPost({ ...post });
    }
  };

  return (
    <>
      <PhotoViewer
        open={!!viewPhoto}
        close={closePhotoViewer}
        selected={viewPhoto}
        images={selectedFiles}
      />
      <Layout>
        <div className="bg-base-100">
          <div className="container mx-auto px-6 my-10">
            <Link to="/" className="btn btn-ghost mb-4 -ml-5">
              <ArrowLeft className="mr-2" />
              Back to Planner
            </Link>
            <span className="prose">
              <h1 className="mb-8">Publish Post</h1>
            </span>

            <div className="lg:flex items-start gap-8">
              <div className="card w-full bg-neutral  mb-8 drop-shadow-xl lg:max-w-md">
                <div className="card-body px-10 pb-12">
                  <span className="prose">
                    <h2 className="mb-2">Photos</h2>
                  </span>

                  <div className="alert shadow-lg bg-base-300 mb-4">
                    <div>
                      <Info />
                      <div>
                        <h3 className="font-bold">
                          The photos are not downloading?
                        </h3>
                        <div className="text-xs">
                          Long-press or right click on each image to save them
                          locally.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-4">
                    {post?.posts_media &&
                      post.posts_media
                        .sort((a, b) =>
                          a?.order && b?.order ? a.order - b.order : 0
                        )
                        .map((img) => (
                          <div
                            className="mb-2 relative tooltip tooltip-accent"
                            data-tip={img.title}
                            key={img.id}
                          >
                            <img
                              className="w-full rounded-lg cursor-pointer"
                              src={img.src_thumbnail || ""}
                              alt={img.title || ""}
                            />
                          </div>
                        ))}
                  </div>

                  <button
                    className="btn btn-primary w-full mb-2"
                    onClick={downloadFiles}
                  >
                    Download photos
                  </button>

                  {isSafari && (
                    <div className="alert alert-warning shadow-lg my-2">
                      <div>
                        <span>
                          Safari can't download all the images at once. You will
                          be prompted to save one image at the time.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card w-full bg-neutral mb-8 drop-shadow-xl">
                <div className="card-body px-10 pb-12">
                  <span className="prose">
                    <h2 className="mb-2">Descriptions</h2>
                  </span>

                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
                    {post?.posts_for_feeds &&
                      post.posts_for_feeds.map((p) => {
                        const hasAutoPost = (p.feed as Feed).auto_publish;

                        return (
                          <div className="mb-2">
                            <span className="prose">
                              <h3 className="mb-2">{(p.feed as Feed).name}</h3>
                            </span>

                            <>
                              <textarea
                                className="textarea textarea-bordered w-full mb-2"
                                rows={4}
                                value={postWithLocation(p)}
                              ></textarea>

                              <div className="flex items-center gap-4 w-full relative">
                                {(p.feed as Feed).slug === "threads_api" && (
                                  <button
                                    className="btn btn-ghost flex-grow bg-gray-950 hover:bg-gray-900 text-white"
                                    onClick={() => postToThreads(p)}
                                    disabled={posting.threads}
                                  >
                                    {posting.threads && (
                                      <span className="loading loading-spinner loading-xs mr-4"></span>
                                    )}
                                    Post to Threads
                                  </button>
                                )}
                                {(p.feed as Feed).slug === "instagram_api" && (
                                  <button
                                    className="btn btn-ghost flex-grow text-white bg-pink-500 hover:bg-pink-800"
                                    onClick={() => postToInstagram(p)}
                                    disabled={posting.instagram}
                                  >
                                    {posting.instagram && (
                                      <span className="loading loading-spinner loading-xs mr-4"></span>
                                    )}
                                    Post to Instagram
                                  </button>
                                )}
                                {(p.feed as Feed).slug === "facebook_api" && (
                                  <button
                                    className="btn btn-ghost flex-grow text-white bg-blue-500 hover:bg-blue-800"
                                    onClick={() => postToFacebook(p)}
                                    disabled={posting.facebook}
                                  >
                                    {posting.facebook && (
                                      <span className="loading loading-spinner loading-xs mr-4"></span>
                                    )}
                                    Post to Facebook
                                  </button>
                                )}
                                <button
                                  className={cx(
                                    "btn ",
                                    !hasAutoPost && " w-full ",
                                    p.published ? "btn-success" : "btn-primary"
                                  )}
                                  onClick={() => publishPost(p)}
                                >
                                  {p.published ? (
                                    <>
                                      <CheckCircle className="mr-2" /> Published
                                    </>
                                  ) : !hasAutoPost ? (
                                    "Copy post content"
                                  ) : (
                                    <Copy />
                                  )}
                                </button>
                              </div>

                              {p.live_post_id && (
                                <div className="mt-2">
                                  {(p.feed as Feed).slug ===
                                    "instagram_api" && (
                                    <a
                                      className="btn btn-ghost w-full"
                                      href={p.live_post_id}
                                    >
                                      <Link2 /> View Instagram Post
                                    </a>
                                  )}
                                  {(p.feed as Feed).slug === "facebook_api" && (
                                    <a
                                      className="btn btn-ghost w-full"
                                      href={`https://facebook.com/${p.live_post_id}`}
                                    >
                                      <Link2 /> View Facebook Post
                                    </a>
                                  )}
                                </div>
                              )}
                            </>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

function postWithLocation(p: PostsForFeed) {
  const feed = p.feed as Feed;
  const content = p.content ?? "";
  const location = (p.location as Location) || null;

  if (
    p.location &&
    Object.keys(p.location).length > 0 &&
    feed.location_type === "EMBED"
  ) {
    if (content.includes("{LOCATION}")) {
      return content.replace("{LOCATION}", formatLocation(location, true));
    }

    return content + "\n" + formatLocation(location, true);
  }

  if (content.includes("{LOCATION}")) {
    return content.replace("{LOCATION}", "");
  }

  return content;
}
