import React, { useState } from "react";
import cx from "classnames";
import { ImageThumbnail } from "../../../components/ImageThumbnail";
import { SelectedFile, UpdateFile } from "../../../types/PostSelection";
import "./styles.scss";
import { PhotoViewer } from "../../../components/PhotoViewer";

export function FilePicker({
  files,
  updateFile,
  createPost,
  editMode,
}: {
  files: SelectedFile[];
  updateFile: UpdateFile;
  createPost: (value: SelectedFile[]) => void;
  editMode: boolean;
}) {
  const [preview, setPreview] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const close = () => {
    setPreview(false);
    setSelected(null);
  };

  const sorted = files
    .filter((f) => f.isSelected)
    .sort((a, b) =>
      a?.selectedAt && b?.selectedAt ? a.selectedAt - b.selectedAt : 0
    );

  return (
    <>
      <div className="file-picker overflow-y-auto overflow-x-hidden flex-wrap pt-4 px-3">
        <div className="file-picker-grid grid gap-4 grid-cols-3 min-[500px]:grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-3 xl:grid-cols-4">
          {files.map((img) => (
            <ImageThumbnail
              key={img.id + img.name}
              id={img.id!}
              title={img.name}
              url={img.url}
              minetype={img.mine_type}
              rating={img.rating}
              isUsed={img.isUsed}
              isSelected={img.isSelected}
              selectedOrder={
                img.isSelected &&
                sorted.findIndex((f) => f.selectedAt === img.selectedAt) + 1
              }
              isUploading={img.isUploading}
              onSelect={() =>
                updateFile(img.name, {
                  isSelected: !img.isSelected,
                  selectedAt: Date.now(),
                })
              }
              onViewerSelection={() => {
                console.log("VIEW IMAGE", img);
                setPreview(true);
                setSelected(img._id ?? null);
              }}
            />
          ))}
        </div>
      </div>

      <div className="p-3 pt-0">
        <button
          className={cx("btn btn-primary w-full mt-6")}
          disabled={sorted.length === 0}
          onClick={() => createPost(sorted)}
        >
          {editMode ? "Update" : "Create"} a post
        </button>
      </div>

      {preview && (
        <PhotoViewer
          images={files}
          open={preview}
          selected={selected}
          close={close}
        />
      )}
    </>
  );
}
