import React from "react";
import cx from "classnames";
import "./styles.scss";
import { CheckCircle, Maximize2, Star } from "react-feather";

export function ImageThumbnail({
  id,
  title,
  url,
  minetype,
  rating,
  isUsed,
  isSelected,
  selectedOrder,
  isUploading,
  onSelect,
  onViewerSelection,
}: {
  id: number;
  title: string;
  url: string;
  minetype: string;
  rating?: number;
  isUsed?: boolean;
  isSelected?: boolean;
  selectedOrder?: number | false;
  isUploading?: boolean;
  onSelect: () => void;
  onViewerSelection?: () => void;
}) {
  const isVideo = minetype.startsWith("video");

  return (
    <div
      className={cx(
        "group thumbnail relative w-20 h-20 tooltip tooltip-accent cursor-pointer",
        isUploading && " opacity-50"
      )}
      data-tip={title}
      key={id}
    >
      {onViewerSelection && (
        <span
          onClick={onViewerSelection}
          className="absolute badge badge-accent top-2 left-2 z-10 invisible group-hover:visible"
        >
          <Maximize2 size={8} />
        </span>
      )}

      {rating && (
        <span className="absolute top-2 left-2 z-10 group-hover:invisible badge badge-neutral text-primary text-xs">
          {rating + ""}
          &nbsp;
          <Star size={12} />
        </span>
      )}

      {isUsed && (
        <span className="absolute bottom-2 left-2 z-10 group-hover:invisible badge badge-success text-white text-xs">
          <CheckCircle size={12} />
        </span>
      )}

      {isSelected && (
        <span className="absolute badge badge-primary top-2 right-2 z-10">
          {selectedOrder}
        </span>
      )}
      <div
        className={cx(
          "absolute w-full h-full rounded-lg flex items-center justify-center overflow-hidden",
          isSelected ? "border-4 border-primary" : "hover:bg-contain",
          isUsed && " opacity-40 hover:opacity-100"
        )}
        onClick={onSelect}
      >
        {isUploading && (
          <span className="absolute loading loading-spinner loading-lg bg-primary"></span>
        )}

        {!isVideo && (
          <img
            src={url}
            alt=""
            className={cx(
              "object-fill w-full h-full",
              isSelected ? "" : "hover:object-contain"
            )}
          />
        )}

        {isVideo && (
          <video
            src={url}
            className={cx(
              "object-fill w-full h-full",
              isSelected ? "" : "hover:object-contain"
            )}
          />
        )}
      </div>
    </div>
  );
}
