import React, { ReactElement } from "react";
import cx from "classnames";

export function Card({
  children,
  className,
  noPadding,
  notFull,
}: {
  children: ReactElement;
  className?: string;
  noPadding?: boolean;
  notFull?: boolean;
}) {
  return (
    <div
      className={cx(
        "card bg-neutral mb-8 drop-shadow-sm rounded-md",
        notFull ? "" : "w-full",
        className
      )}
    >
      <div className={cx("card-body", noPadding && " p-0")}>{children}</div>
    </div>
  );
}
