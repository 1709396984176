// import cx from "classnames";
import { Modal } from "../../../components/Modal";
import React, { useCallback, useEffect, useState } from "react";
import { PostCompose } from "../../../components/PostCompose";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useForm,
} from "react-hook-form";
import { ImageThumbnail } from "../../../components/ImageThumbnail";
import { getFeeds } from "../../../api/feeds";
import { handleError } from "../../../utils/handleError";
import { schedule } from "../../../api/schedule";
import { schedule_update } from "../../../api/schedule_update";
import { getSignedUrl } from "../../../api/files";
import {
  PostSelection,
  ResetSelectedFiles,
  SelectedFile,
  SetSelectedPost,
  SetSelection,
  Step,
  UpdateFile,
  UpdatePosts,
} from "../../../types/PostSelection";
import { Post } from "../../../types/api/Post";
import { Feed, Feeds, TypeMapping } from "../../../types/api/Feed";
import { Link } from "react-router-dom";
import { PhotoViewer } from "../../../components/PhotoViewer";
import { SavedContent } from "../../../types/api/SavedContent";
import { supabase } from "../../../supabaseClient";
import { PostgrestError } from "@supabase/supabase-js";
import { Card } from "../../../components/Card/Card";

export function CreatePostModal({
  selection: { openModal, selected, step, isExisting, currentPost },
  setSelection,
  updatePosts,
  selectedPost,
  setSelectedPost,
  updateFile,
  resetSelectedFiles,
}: {
  selection: PostSelection;
  setSelection: SetSelection;
  updatePosts: UpdatePosts;
  selectedPost: Post | null;
  setSelectedPost: SetSelectedPost;
  updateFile: UpdateFile;
  resetSelectedFiles: ResetSelectedFiles;
}) {
  const [feeds, setFeeds] = useState<Feeds>([]);
  const [savedDescriptions, setSavedDescriptions] = useState<SavedContent[]>(
    []
  );
  const [openViewer, setOpenViewer] = useState(false);
  const [viewerSelection, setViewerSelection] = useState<string | null>(null);

  const { register, watch, handleSubmit, setValue, getValues, reset } =
    useForm<FieldValues>();

  useEffect(() => {
    getFeeds()
      .then((files) => setFeeds(files))
      .catch((err) => handleError(err));
    supabase
      .from("saved_content")
      .select()
      .then(({ data, error }) => {
        if (!error) setSavedDescriptions(data as SavedContent[]);
        else handleError(error as PostgrestError);
      });

    if (!isExisting) {
      const tomorrow = new Date();
      tomorrow.setUTCHours(tomorrow.getUTCHours() + 24);
      const defaultDate = new Date(tomorrow);
      defaultDate.setUTCHours(8);
      defaultDate.setUTCMinutes(30);
      setValue("date", defaultDate.toISOString().slice(0, 16));
    }
  }, []);

  const updateMedia = useCallback(() => {
    setSelection((s) => ({
      ...s,
      openModal: false,
      update: true,
    }));
  }, [setSelection]);

  const nextStep = useCallback(() => {
    if (step === 1) {
      const values = getValues("default");

      for (const feed of feeds) {
        const type_mapping = feed.type_mapping as TypeMapping;
        setValue(
          `${feed.slug}.post_content`,
          replaceVariants(values.post_content, feed.slug!, savedDescriptions)
        );
        setValue(
          `${feed.slug}.type`,
          type_mapping[values.type]?.type || values.type
        );
        if (feed.auto_publish) {
          setValue(`${feed.slug}.auto_publish`, true);
        }
        setValue(`${feed.slug}.location`, values.location);
      }

      setSelection((s) => ({ ...s, step: 2 }));
    }
  }, [setSelection, feeds, setValue, getValues, step]);

  useEffect(() => {
    async function runAsync() {
      if (!openModal && selectedPost) {
        const date = new Date(selectedPost.publish_at!).getTime();
        const diff = new Date().getTimezoneOffset() * 60 * 1000;
        const dateWithTimezone = new Date(date - diff);

        setValue("date", dateWithTimezone.toISOString().slice(0, 16));

        let selectedImages: SelectedFile[] = [];
        resetSelectedFiles();

        if (selectedPost.posts_media) {
          for (const media of selectedPost.posts_media) {
            selectedImages.push({
              id: media.id,
              name: media.title!,
              isSelected: true,
              mine_type: media.mine_type!,
              url: await getSignedUrl(media.src_thumbnail!),
              selectedAt: Date.now(),
            });

            updateFile(media.title!, {
              isSelected: true,
              selectedAt: Date.now(),
            });
          }
        }

        setSelection((s) => ({
          ...s,
          step: 2,
          selected: selectedImages,
          last_update: Date.now(),
          isExisting: true,
          openModal: true,
          currentPost: selectedPost,
        }));

        if (selectedPost.posts_for_feeds) {
          for (const post_for_feed of selectedPost.posts_for_feeds) {
            setValue(
              `${(post_for_feed.feed as Feed).slug}.post_content`,
              post_for_feed.content
            );
            setValue(
              `${(post_for_feed.feed as Feed).slug}.type`,
              post_for_feed.type
            );

            if ((post_for_feed.feed as Feed).auto_publish) {
              setValue(
                `${(post_for_feed.feed as Feed).slug}.auto_publish`,
                post_for_feed.auto_publish
              );
            }

            setValue(
              `${(post_for_feed.feed as Feed).slug}.location`,
              post_for_feed.location
            );
          }
        }
      }
    }
    runAsync();
  }, [selectedPost, currentPost]);

  const cancel = useCallback(
    function () {
      setSelectedPost(null);
      setSelection((s) => ({
        ...s,
        step: 1,
        selected: [],
        openModal: false,
        isExisting: false,
        currentPost: null,
      }));
      reset({ default: { post_content: "", type: "" } });
      resetSelectedFiles();
    },
    [setSelectedPost, setSelection, reset, resetSelectedFiles]
  );

  const onClose = useCallback(() => {
    cancel();
  }, [cancel]);

  useEffect(() => {
    const postType = getPossibleTypes(selected);
    console.log(
      "SELECTED CHANGE",
      postType,
      getValues("default.type"),
      !getValues("default.type")
    );
    if (postType && !getValues("default.type")) {
      setValue("default.type", postType[0]);
    }
  }, [selected, getValues, setValue]);

  // async function deletePost(post) {
  //   await schedule_delete(post);
  //   cancel();
  //   getPosts();
  // }

  async function onSubmit(values: FieldValues) {
    try {
      if (isExisting) {
        await schedule_update(feeds, values, selected, selectedPost!);
      } else {
        await schedule(feeds, values, selected);
      }

      updatePosts();
      cancel();
    } catch (e) {
      handleError(e as Error);
    }
  }

  const postTypes = getPossibleTypes(selected) || [];

  const selectImage = (id: string) => {
    setViewerSelection(id);
    setOpenViewer(true);
  };

  const closeImageViwer = () => {
    setViewerSelection(null);
    setOpenViewer(false);
  };

  return (
    <>
      <Modal
        show={openModal}
        title={"Create Post"}
        onClose={onClose}
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <Footer
            selected={selected}
            register={register}
            watch={watch}
            nextStep={nextStep}
            step={step}
            isExisting={isExisting!}
            currentPost={currentPost!}
          />
        }
      >
        <>
          {isExisting &&
            currentPost?.publish_at &&
            new Date(currentPost?.publish_at) < new Date() && (
              <Card>
                <div className="flex justify-between items-center gap-6 max-lg:flex-col">
                  <span className="prose">
                    <h2>Ready to post?</h2>
                  </span>

                  <Link to={`/posts/${currentPost?._id}`}>
                    <button type="button" className="btn btn-primary">
                      View Publish Page
                    </button>
                  </Link>
                </div>
              </Card>
            )}

          <div className="flex justify-start gap-4 mb-4">
            {selected.map((img) => (
              <ImageThumbnail
                id={img.id!}
                title={img.name}
                url={img.url}
                minetype={img.mine_type}
                onSelect={() => selectImage(img._id!)}
              />
            ))}
          </div>

          <button
            type="button"
            className="btn btn-outline btn-sm mb-6"
            onClick={updateMedia}
          >
            Update Media
          </button>

          {step === 1 && (
            <DefaultPost
              register={register}
              watch={watch}
              setValue={setValue}
              postTypes={postTypes}
            />
          )}

          {step === 2 && (
            <PostPerFeeds
              feeds={feeds}
              register={register}
              watch={watch}
              setValue={setValue}
              postTypes={postTypes}
              selectedFiles={selected}
            />
          )}
        </>
      </Modal>

      {openViewer && (
        <PhotoViewer
          open={openViewer}
          close={closeImageViwer}
          selected={viewerSelection}
          images={selected}
        />
      )}
    </>
  );
}

function DefaultPost({
  register,
  watch,
  setValue,
  postTypes,
}: {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  postTypes: string[];
}) {
  return (
    <PostCompose
      register={register}
      watch={watch}
      setValue={setValue}
      name="default"
      postTypes={postTypes}
    />
  );
}

function PostPerFeeds({
  feeds,
  register,
  watch,
  setValue,
  postTypes,
  selectedFiles,
}: {
  feeds: Feeds;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  postTypes: string[];
  selectedFiles: SelectedFile[];
}) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {feeds.map((feed) => (
        <PostCompose
          key={feed.id}
          title={feed.name!}
          register={register}
          watch={watch}
          setValue={setValue}
          name={feed.slug!}
          canAutoPublish={feed.auto_publish!}
          postTypes={postTypes}
          typeMapping={feed.type_mapping as TypeMapping}
          feed={feed}
          selectedFiles={selectedFiles}
        />
      ))}
    </div>
  );
}

function Footer({
  register,
  selected,
  watch,
  nextStep,
  step,
  isExisting,
  currentPost,
}: {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  selected: SelectedFile[];
  nextStep: () => void;
  step: Step;
  isExisting: boolean;
  currentPost: Post;
}) {
  return (
    <>
      <input
        type="datetime-local"
        placeholder="Write the post description"
        {...register("date")}
        className="input input-bordered bg-base-100  mb-4 md:mb-0"
      />

      <div className="flex flex-col-reverse !ml-0 md:flex-row">
        {step === 1 && (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={nextStep}
            disabled={
              Object.keys(selected).length === 0 ||
              watch("default.post_content").length === 0
            }
          >
            Next
          </button>
        )}
        {isExisting && (
          <Link to={`/posts/${currentPost._id}`}>
            <button type="button" className="btn btn-ghost mr-4">
              View Post
            </button>
          </Link>
        )}
        {step === 2 && (
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={nextStep}
          >
            {isExisting ? "Update" : "Create"} Post
          </button>
        )}
      </div>
    </>
  );
}

function getPossibleTypes(selected: SelectedFile[]) {
  const isCarousel = selected.length > 1;
  const firstFile = selected[0];

  if (isCarousel) {
    return ["CAROUSEL", "STORIES"];
  } else if (firstFile?.name?.includes(".video-thumbnail.jpg")) {
    return ["VIDEO", "REEL", "STORY-VIDEO"];
  } else if (firstFile?.mine_type?.startsWith("image/")) {
    return ["PHOTO", "STORY-PHOTO"];
  }

  return null;
}

function replaceVariants(
  content: string,
  feed: string,
  savedDescriptions: SavedContent[]
) {
  return content.replace(
    /\{\{VARIANTS\((.*?)\)\: \"(.*?)\"\}\}/gi,
    (_match: string | null | undefined, p1: string) => {
      const saved = savedDescriptions.find((s) => s.id.toString() == p1);
      console.log("VARIANT CHANGE", _match, p1, savedDescriptions);
      return saved?.variants[feed] ?? saved?.content ?? "";
    }
  );
}
